.media-container {
  width: 70%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

.h-title-media {
  font-size: 70px;
  font-weight: 100;
  position: relative;
  z-index: 1;
  color: #fff;
  font-family: "PerpetuaTitlingMTLight2";
  margin-bottom: 0;
}

/*
.h-title-media::after{
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90px; 
  height: 90px; 
  background-color: #9b793e; 
  border-radius: 50%; 
  margin-top: 5px;
  content: "";
  z-index: -1;
  opacity: 0.4;
}
*/

.swiper {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

@media (max-width: 1000px) {
  .h-title-media {
    font-size: 55px;
  }
}

@media (max-width:700px) {
  .media-container {
    justify-content: center;
    align-items: center;
  }

  .h-title-media {
    padding-top: 10px;
  }

  .swiper {
    margin-bottom: 0;
    padding: 30px !important;
    margin: 0;
  }
}

@media (max-width: 500px) {
  .h-title-media {
    font-size: 40px;
  }

  .h-title-media::after {
    width: 40px;
    height: 40px;
  }
}