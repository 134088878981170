/* 
    font-family: "BellagiaDisplay";

*/
@font-face {
    font-family: "trendsetter-Regular";
    src:
        local("trendsetter-Regular"),
        url("../../assets/fonts/trendsetter-Regular.ttf");
}

.about-section {
    background: rgb(22,22,22);
    background: linear-gradient(180deg, rgba(22,22,22,1) 0%, rgba(33,34,36,1) 100%);
    color: #c0c0c0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    box-sizing: border-box;
}

.about-container {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.h-title3 {
    font-size: 70px;
    font-weight: 100;
    font-family: "PerpetuaTitlingMTLight2";
    margin-top: 30;
    margin-bottom: 0;
    text-align: left;
}

/* .h-title3::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90px;
    height: 90px;
    background-color: #9b793e;
    border-radius: 50%;
    margin-top: 5px;
    content: "";
    z-index: -1;
    opacity: 0.4;
}

*/

.img-container {
    margin-top: 50px;
}

.about-img {
    max-height: 500px;
    border: 1px dotted #9b793e;
    float: left;
    opacity: 80%;
    margin-right: 25px;
    margin-bottom: 10px;
}

.about-text {
    text-align: justify;
    font-size: 17px;
    line-height: 30px;
    margin-block-start: 0;
    font-weight: 300;
}

span {
    color: #9b793e;
    font-family: "PerpetuaTitlingMTLight2";
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 2px;
}

@media (max-width: 1280px) {
    .about-img {
        width: 100%;
        max-height: none;
    }
}


@media (max-width: 1000px) {
    .about-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }

    .h-title3 {
        font-size: 55px;
    }

    .img-container {
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .about-img {
        width: 100vw;
        width: 100%;

    }

    .about-text {
        font-size: 17px;

    }
}

@media (max-width: 700px) {
    .about-text {
        font-size: 14px;
        text-align: center;
    }

    span {
        font-size: 18px;
    }

    .about-container {
        align-items: center;
    }

}

@media (max-width: 500px) {
    .h-title3 {
        font-size: 40px;
    }

    .h-title3::after {
        width: 40px;
        height: 40px;
    }
}