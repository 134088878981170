.hero-section {
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/img/cover3.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hero-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.hero-h1-section {
    font-size: 110px;
    font-weight: 100;
    margin-top: 100px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;

}

.hero-h2 {
    font-size: 60px;
    font-weight: 300;
    font-style: italic;
    margin-top: 20px;
    border: 1px dotted #9b793e;
    padding: 15px;
    color: #9b793e;
}

.font-icon {
    font-size: 30px;
    color: #fff;
}

.insta {
    color: #fff;
}

.icon-container {
    display: flex;
    flex-direction: row;
}

.icon-container a {
    color: #fff;
}

.icon-style {
    font-size: 30px;
    margin: 10px;
    transition: 0.3s ease-in-out;
}

.icon-style:hover {
    color: #9b793e;
    cursor: pointer;
}

.icon-style-btn {
    font-size: 12px;
}

.hero-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(22,22,22,0) 80%, rgba(22,22,22,1) 100%);
    pointer-events: none;
}

@media (max-width: 1000px) {
    .hero-h1-section {
        font-size: 80px;
    }

    .hero-h2 {
        font-size: 40px;
    }

    .font-icon {
        font-size: 20px;
    }
}

@media (max-width: 700px) {
    .hero-section {
        background-position-x: -600px;
        background-position-y: 400px;
        max-height: 400px;

    }

    .hero-h1-section {
        margin-top: 140px;
        font-size: 50px;
    }

    .hero-h2 {
        font-size: 20px;
        padding: 20px 40px;
    }

    .font-icon {
        font-size: 14px;
    }

    .icon-container{
        margin-bottom: 20px;
    }

}

@media (max-width: 400px){
    .hero-section {
        background-position-x: -700px;
        background-position-y: 400px;
        max-height: 400px;

    }

    .hero-container{
        align-items: center;
    }
}