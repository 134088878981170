.icon-up{
    color: #c0c0c0;
    position: fixed;
    right: 100px;
    bottom: 100px;
    font-size: 20px;
    width: 30px;
    height: 30px;
    border: #9b793e 1px solid;
    padding: 10px;
    border-radius: 100%;
    transition: all ease-in-out 0.3s;
    opacity: 0.8;
}

.icon-up:hover{
    cursor: pointer;
    background-color: #9b793e;
    color: #161615;
}

@media (max-width: 1150px) {
    .icon-up{
        width: 15px;
        width: 20px;
        height: 20px;
        right: 150px;
        bottom: 10px
    }
  }

  @media (max-width: 900px) {
    .icon-up{
        width: 15px;
        width: 20px;
        height: 20px;
        right: 10px;
        bottom: 10px
    }
  }