.nav {
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 100;
  font-size: 16px;
}

.nav-section {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
}


.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  transition: 0.3s ease-in-out;
}

.nav li a {
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  color: #9b793e;
  transition: 0.3s ease-in-out;
}

.nav li a:hover {
  background-color: #f8f7f2;
  color: #45464B;
}

.logo {
  width: 150px;
}

.btn-lang {
  border: 0;
  background-color: #ffffff;
  color: #161616;
  padding: 20px 20px;
  cursor: pointer;
  font-size: 12px;
  transition: 0.3s ease-in-out;
  border-right: 1px solid #9b793e;
}

.btn-lang.active {
  color: #c0c0c0;
  background-color: #f0f0f0;
}

.btn-lang:last-child {
  border-right: 0;
}

.btn-lang:hover {
  color: #c0c0c0;
  background-color: #f0f0f0;
}

/* Hide the links inside the navigation menu (except for logo/home) */
/* .nav-section .nav-items {
  display: none;
} */

.nav-section .hamburger-menu {
  color: #9b793e;
  background: #fff;
  display: block;
  position: absolute;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 20px;
  right: 0;
  top: 0;
  display: none;
}

@media (max-width: 1300px) {
  .nav-section .hamburger-menu {
    display: block;
    border: none;
  }

  .nav ul,
  .nav .btn-lang-container {
    display: none;
  }

  .home-item {
    margin-top: 48px;
  }

  .btn-lang-container {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .nav-section {
    width: 90%;
    flex-wrap: wrap;
  }

  .nav-items {
    width: 100%;
  }

  .logo {
    position: absolute;
    top: 0;
    padding-top: 13px;
  }
}