.swiper-button-prev,
.swiper-button-next {
    color: #9b793e !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #fff;
}

.swiper{
    padding: 100px;
    margin-bottom: 100px;
}

.video-text{
    font-size: 20px;
    color: #c0c0c0;
    font-style: italic;
}

.yt-video{
    max-width: 800px;
    height: 600px;
    width: 100%;
}

@media (max-width: 900px) {
    .yt-video{
        height: 400px;
    }
}

@media (max-width: 500px) {
   .video-text{
    font-size: 14px;
   }
}