.footer{
    background-color:#161615; 
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #c0c0c0;
}

@media (max-width: 500px) {
    .footer{
        font-size: 14px;
    }
}
