#disc {
    background-image: url('../../assets/img/DSC_8694-2.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
}

.disc-container {
    width: 70%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 70px;

}

.h-title-disc {
    font-size: 70px;
    font-weight: 100;
    font-family: "PerpetuaTitlingMTLight2";
    margin-top: 30;
    margin-bottom: 0;
    color: #c0c0c0;
    text-align: left;
}

/*
.h-title-disc::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90px;
    height: 90px;
    background-color: #9b793e;
    border-radius: 50%;
    margin-top: 5px;
    content: "";
    z-index: -1;
    opacity: 0.4;
}
*/

.cd-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.cd-container {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}

.cd-img {
    max-width: 500px;
    padding-bottom: 10px;
    display: block;
    width: 100%;
}

.text-c {
    color: #fff;
    padding-top: 30px;
    text-align: justify;
    margin-left: 10px;
    margin-top: 0;
    font-weight: 400;
    margin-top: 10px;
    font-size: 17px;
    color: #c0c0c0;
    line-height: 30px;
}

.text-c2 {
    color: #c0c0c0;
    padding-top: 30px;
    font-weight: 500px;
}

.altra-volta {
    display: block;
    color: #9b793e;
    font-family: "PerpetuaTitlingMTLight2";
    font-weight: bold;
    font-size: 20px;
}

.a1 {
    display: block;
    font-family: "Roboto";
    color: #c0c0c0;
    font-weight: 400;
    font-size: 18px;
}

.audio-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.audio-clips {
    display: block;
    padding: 10px;
    opacity: 0.8;
}

.title-playlist {
    display: block;
    margin-top: 50px;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-align: start;
    width: 100%;
    justify-content: center;
}

.title-playlist2 {
    color: #9b793e;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    text-align: start;
    text-align: center;
}

.audio-text {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 3px;
    text-align: left;
}

.audio-small-section {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.line {
    width: 100%;
    display: none;
}

@media (max-width: 1400px) {
    .title-playlist2 {
        width: 320px;
    }
}

@media (max-width: 1000px) {
    .h-title-disc {
        font-size: 55px;
    }

    .title-playlist2 {
        width: auto;
    }

    .line {
        display: block;
        margin-top: 60px;
        margin-bottom: 50px;
    }

    .audio-section {
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media (max-width: 700px) {
    .disc-container {
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .text-c {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
    }

    .audio-small-section {
        text-align: center;
    }

    .title-playlist {
        text-align: center;
        font-size: 15px;
    }

    .title-playlist2 {
        text-align: center;
        font-size: 16px;
    }

    .audio-text {
        text-align: center;
    }

    .audio-clips {
        width: 90%;
    }

    .altra-volta{
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    .h-title-disc {
        font-size: 40px;
    }

    .h-title-disc::after {
        width: 40px;
        height: 40px;
    }
}