.contact-section {
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/img/contact-cover.webp');
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
}

.contact-container {
    width: 70%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
}

.h-title-contact {
    font-size: 70px;
    font-weight: 100;
    position: relative;
    z-index: 1;
    color: #fff;
    font-family: "PerpetuaTitlingMTLight2";
    margin-bottom: 0;
}
/*
.h-title-contact::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90px;
    height: 90px;
    background-color: #9b793e;
    border-radius: 50%;
    margin-top: 5px;
    content: "";
    z-index: -1;
    opacity: 0.4;
}
*/

.contact-img {
    border-radius: 100%;
    width: 150px;
    border: #9b793e 2px solid;
    margin: 0;
    padding: 0;
}

.title-contact {
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 200;
}

.contact-h {
    font-size: 22px;
    font-weight: 300;
    color: #9b793e;
}

.contact-box {
    display: flex;
    flex-direction: column;
}

.contact-p {
    font-size: 16px;
    font-weight: 200px;
}

.icon-contact {
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    color: #9b793e;
}

@media (max-width: 1000px) {
    .h-title-contact {
        font-size: 55px;
    }


}

@media (max-width: 700px) {

    .contact-container {
        align-items: center;
    }

    .contact-box {
        align-items: center;
    }

}

@media (max-width: 500px) {
    .h-title-contact {
        font-size: 40px;
    }

    .h-title-contact::after {
        width: 40px;
        height: 40px;
    }

    .title-contact {
        font-size: 20px;
    }

    .contact-p {
        font-size: 15px;
    }

    .contact-h {
        font-size: 18px;
    }

}